import React from 'react';
import classNames from 'classnames';

import styles from './Progress.module.scss';

const Progress = ({ count = 0, title, totalCount = 0 }) => {
  let getProgress = (statCount, totalCount) => {
    return (100 / totalCount * statCount).toFixed();
  };

  return (
    <div
      className={styles.progress}
      data-progress={getProgress(count, totalCount)}
    >
      <div className={styles.wrapper}>
        <div className={styles.circle}>
          <div className={classNames(styles.circleMask, styles.circleMaskFull)}>
            <div className={styles.circleFill} />
          </div>
          <div className={styles.circleMask}>
            <div className={styles.circleFill} />
            <div className={classNames(styles.circleFill, styles.circleFillFix)} />
          </div>
          <div className={styles.inset}>
            <div className={styles.info}>
              <span className={styles.count}>{count}</span>
              <span className={styles.title}>{title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
