import lookupBySlug from './lookupBySlug';

const mergeDataSources = (originalData: Array<any>, fields: Array<string>, additionalDataSources: Array<any>) => {

  return originalData.map((item) => {
    const populatedFields = fields.reduce((acc, field, index) => {
      return {
        ...acc,
        [field]: lookupBySlug(item[field], additionalDataSources[index]),
      }
    }, {});
    return {
      ...item,
      ...populatedFields,
    }
  });

}

export default mergeDataSources;
