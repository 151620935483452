import React from 'react';
import classNames from 'classnames';

import styles from './Layout.module.scss';

const Layout = ({ alignment, children }) => {
  return <div className={classNames(styles.layout, styles[alignment])}>{children}</div>;
};

export default Layout;
