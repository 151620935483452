import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './RaceCard.module.scss';

import getDate from '../../utils/getDate';
import getMonth from '../../utils/getMonth';
import getTeamNameClassName from '../../utils/getTeamNameClassName';

/*const getTime = (d: Date, t: Date) => {
  const dt = new Date(d + 'T' + t);
  return dt.getHours() + ':' + dt.getMinutes();
};*/

const RaceCard: React.FunctionComponent = ({
  circuit,
  date,
  fastestLap,
  laps,
  pole,
  raceName,
  round,
  season,
  time,
  winner,
}) => {
  return (
    <Link to={`/season/${season}/${round}/`} className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.calendar}>
          {getDate(date)} {getMonth(date)}
        </div>
        <div
          className={classNames(
            styles.winnerStripe,
            getTeamNameClassName(winner?.team.slug || 'unknown')
          )}
        />
        <div className={styles.details}>
          <h2 className={styles.round}>
            <strong>Round {round}</strong> - {season}
          </h2>
          <div className={styles.nameWrapper}>
            <h1 className={styles.name}>{raceName}</h1>
            <div
              className={styles.flag}
              style={{
                backgroundImage: `url(/images/flags/${circuit.location.country.toLowerCase()}.svg)`,
              }}
            />
          </div>
          <h2 className={styles.circuit}>{circuit.name}</h2>
          <h3 className={styles.country}>{circuit.location.country}</h3>
          {laps && <p className={styles.laps}>{laps} laps</p>}

          {!!pole && (
            <p className={styles.result}>
              <strong>Pole:</strong>
              <br />
              {pole.driver.name}, {pole.team.name}
            </p>
          )}

          {!!winner && (
            <p className={styles.result}>
              <strong>Winner:</strong>
              <br />
              {winner.driver.name}, {winner.team.name}
            </p>
          )}
          {!!fastestLap && (
            <p className={styles.result}>
              <strong>Fastest:</strong> {fastestLap.time}
              <br />
              {fastestLap.driver.name}, {fastestLap.team.name}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default RaceCard;
