import React from 'react';

import getDate from '../../utils/getDate';
import getMonth from '../../utils/getMonth';

import Layout from '../furniture/Layout/Layout';

import styles from './SeasonTitle.module.scss';

const SeasonTitle = ({ fromDate, toDate, year }) => {
  return (
    <Layout>
      <h1 className={styles.title}>{year} season</h1>
      <p className={styles.byline}>
        {getDate(fromDate)} {getMonth(fromDate)} - {getDate(toDate)}{' '}
        {getMonth(toDate)} {year}
      </p>
    </Layout>
  );
};

export default SeasonTitle;
