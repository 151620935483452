import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import Layout from '../furniture/Layout/Layout';

import styles from './SeasonSelect.module.scss';

type Props = {
  from: number;
  selected: number;
  to: number;
}

const SeasonSelect: React.FunctionComponent<Props> = ({ from, selected, to }) => {
  const years = [];

  for (let i = from; i <= to; i++) {
    years.push(i);
  }

  return (
    <Layout>
      <h1 className={styles.title}>Seasons</h1>

      <ul className={styles.seasons}>
        {years.map((year) => (
          <li className={styles.season} key={year}>
            <Link className={classNames(styles.seasonLink, year === selected ? styles.seasonSelected : undefined)} to={`/season/${year}`}>{year.toString().substr(2,2)}</Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export default SeasonSelect;
