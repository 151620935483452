import React from 'react';
import { graphql } from 'gatsby';

import mergeDataSources from '../utils/mergeDataSources';

import Layout from '../components/furniture/Layout/Layout';
import SeasonLeaderBoard from '../components/SeasonLeaderBoard/SeasonLeaderBoard';
import PageLayout from '../components/PageLayout/PageLayout';
import ProgressBoard from '../components/ProgressBoard/ProgressBoard';
import RaceList from '../components/RaceList/RaceList';
import SEO from '../components/seo';
import SeasonSelect from '../components/SeasonSelect/SeasonSelect';
import SeasonTitle from '../components/SeasonTitle/SeasonTitle';
import SeasonScoreCard from "../components/ScoreCard/SeasonScoreCard/SeasonScoreCard"

const Season = ({ data }) => {
  const {
    allDrivers: { nodes: drivers },
    allRaces: { nodes: races },
    allDriverStandings: { nodes: driverStandings },
    allTeams: { nodes: teams },
    allTeamStandings: { nodes: teamStandings },
  } = data;

  const season = races[0].season;

  const racesWithWinner = races.filter((race) => !!race.winner);
  const latestDriverStandings = mergeDataSources(
    driverStandings.filter(
      (standing) =>
        standing.round === racesWithWinner[racesWithWinner.length - 1].round
    ),
    ['driver', 'team'],
    [drivers, teams]
  );
  const latestTeamStandings = mergeDataSources(
    teamStandings.filter(
      (standing) =>
        standing.round === racesWithWinner[racesWithWinner.length - 1].round
    ),
    ['team'],
    [teams]
  );

  return (
    <PageLayout>
      <SEO title={`${season} Season`} />
      <SeasonSelect from={2000} selected={parseInt(season, 10)} to={2020} />

      <SeasonTitle
        fromDate={races[0].date}
        toDate={races[races.length - 1].date}
        year={season}
      />

      <ProgressBoard
        completedRaces={races.filter((race) => race.hasResults).length}
        driverWinners={
          [...new Set(racesWithWinner.map((race) => race.winner?.driver))]
            .length
        }
        numberDrivers={drivers.length}
        numberTeams={teams.length}
        teamWinners={
          [...new Set(racesWithWinner.map((race) => race.winner?.team))].length
        }
        totalRaces={races.length}
      />

      <Layout>

        <SeasonLeaderBoard
          drivers={drivers}
          driverStandings={latestDriverStandings}
          number={3}
          season={season}
          teams={teams}
          teamStandings={latestTeamStandings}
        />
      </Layout>

      <SeasonScoreCard drivers={drivers} races={races} />

      <RaceList drivers={drivers} races={races} teams={teams} />
    </PageLayout>
  );
};

export const query = graphql`
  query($season: Int) {
    allDrivers(filter: { season: { eq: $season } }) {
      nodes {
        code
        name
        nationality
        number
        slug
      }
    }
    allTeams(filter: { season: { eq: $season } }) {
      nodes {
        name
        nationality
        slug
      }
    }
    allRaces(filter: { season: { eq: $season } }) {
      nodes {
        name
        round
        season
        hasResults
        circuit {
          name
          slug
          location {
            city
            country
            lat
            lng
          }
        }
        winner {
          driver
          team
          time
        }
        fastestQualifying {
          times {
            q1
            q2
            q3
          }
          team
          driver
        }
        fastestLap {
          driver
          team
          time
        }
        date
        time
        pole {
          driver
          team
        }
      }
    }
    allDriverStandings(filter: { season: { eq: $season } }) {
      nodes {
        season
        round
        driver
        team
        points
        position
        wins
        gainedPoints
        fastestLap
      }
    }
    allTeamStandings(filter: { season: { eq: $season } }) {
      nodes {
        season
        round
        team
        points
        position
        wins
        gainedPoints
        fastestLap
      }
    }
  }
`;

export default Season;
