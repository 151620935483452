import React from 'react';

import Progress from '../Progress/Progress';

import styles from './ProgressBoard.module.scss';

const ProgressBoard = ({ completedRaces, numberDrivers, numberTeams, totalRaces, driverWinners, teamWinners }) => {
  return (
    <div className={styles.wrapper}>
      <Progress count={completedRaces} title="Races" totalCount={totalRaces} />
      <Progress count={numberDrivers} title="Drivers" totalCount={numberDrivers} />
      <Progress count={numberTeams} title="Constructors" totalCount={numberTeams} />
      <Progress
        count={driverWinners}
        title="Different Driver Winners"
        totalCount={numberDrivers}
      />
      <Progress
        count={teamWinners}
        title="Different Constructor Winners"
        totalCount={numberTeams}
      />
    </div>
  );
};

export default ProgressBoard;
