import React, { useState } from 'react';
import classNames from 'classnames';

import LeaderBoard from '../LeaderBoard/LeaderBoard';

import styles from './SeasonLeaderBoard.module.scss';

const SeasonLeaderBoard: React.FunctionComponent = ({
  driverStandings,
  number,
  showAll,
  teamStandings,
  view,
}) => {
  const limitedView = number;
  const [seeAll, setSeeAll] = useState(showAll);

  let buttonAction = seeAll ? 'Reduce' : 'Expand';

  const driverStandingsResult = driverStandings && driverStandings
    .slice(0, seeAll ? 100 : limitedView)
    .map((standing) => ({
      ...standing,
      entrant: standing.driver,
    }));

  const teamStandingsResult = teamStandings && teamStandings
    .slice(0, seeAll ? 100 : limitedView)
    .map((standing) => ({
      ...standing,
      entrant: standing.team,
    }));

  return (
    <div>
      <div className={styles.wrapper}>
        { driverStandingsResult && <LeaderBoard
          standings={driverStandingsResult}
          view={view}
        />}
        { teamStandingsResult && <LeaderBoard
          standings={teamStandingsResult}
          view={view}
        />}
      </div>
      {/*<button
        aria-label={buttonAction}
        className={styles.button}
        onClick={() => setSeeAll(!seeAll)}
      >
        <span
          className={classNames(
            styles.button_icon,
            styles[`button_${buttonAction.toLowerCase()}`]
          )}
        />
      </button>*/}
    </div>
  );
};

export default SeasonLeaderBoard;
