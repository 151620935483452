import React from 'react';

import Layout from '../furniture/Layout/Layout';
import RaceCard from '../RaceCard/RaceCard';

import lookupBySlug from '../../utils/lookupBySlug';

import styles from './RaceList.module.scss';

type Props = {
  drivers: any;
  races: any;
  teams: any;
};

const RaceList: React.FunctionComponent<Props> = ({
  drivers,
  races,
  teams,
}) => {
  return (
    <Layout>
      <h1 className={styles.title}>Races</h1>

      <div className={styles.wrapper}>
        {races.map((race) => {
          return (
            <RaceCard
              circuit={race.circuit}
              date={race.date}
              fastestLap={
                race.fastestLap
                  ? {
                      driver: lookupBySlug(race.fastestLap?.driver, drivers),
                      team: lookupBySlug(race.fastestLap?.team, teams),
                      time: race.fastestLap.time,
                    }
                  : undefined
              }
              key={race.round}
              laps={race.laps}
              pole={
                race.pole
                  ? {
                      driver: lookupBySlug(race.pole?.driver, drivers),
                      team: lookupBySlug(race.pole?.team, teams),
                    }
                  : undefined
              }
              raceName={race.name}
              round={race.round}
              season={race.season}
              time={race.time}
              winner={
                race.winner
                  ? {
                      driver: lookupBySlug(race.winner?.driver, drivers),
                      team: lookupBySlug(race.winner?.team, teams),
                    }
                  : undefined
              }
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default RaceList;
