import React from 'react';

import BaseScoreCard from './../BaseScoreCard/BaseScoreCard';

import getTeamNameClassName from '../../../utils/getTeamNameClassName';

import lookupBySlug from '../../../utils/lookupBySlug';

const SeasonScoreCard = ({ drivers, races }) => {
  return (
    <BaseScoreCard
      scores={races.map(race => ({
        className: getTeamNameClassName(
          race.hasResults ? race.winner.team : 'unknown'
        ),
        key: race.round,
        url: `/season/${race.season}/${race.round}/`,
        value: race.hasResults
          ? lookupBySlug(race.winner.driver, drivers).code
          : '-',
      }))}
    />
  );
};

export default SeasonScoreCard;
