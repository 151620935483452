import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import getTeamNameClassName from '../../utils/getTeamNameClassName';

import styles from './LeaderBoard.module.scss';
import DriverPhoto from "../DriverPhoto/DriverPhoto"

type Props = {
  byline?: string;
  standings: Array<StandingProps>;
  title: string;
};

type StandingProps = {
  id: string;
  position: string;
  entrant?: any;
  team?: any;
  points: string;
};

const LeaderBoard: React.FunctionComponent<Props> = ({
  byline,
  standings,
  title,
  view,
}) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{title}</h1>

      <ol className={classNames(styles.standings, styles[view])}>
        {standings.map((standing) => {
          const change = standing.previous ? standing.position - standing.previous : 0;

          const changeStyle = (change > 0) ? 'down' : (change < 0) ? 'up' : 'same';

          return (
            <li className={classNames(
              view === 'summary' ? styles.card : styles.row,
              view === 'summary' ? getTeamNameClassName(standing.team?.slug) : ''
            )} key={standing.position}>
              <span
                className={classNames(
                  styles.position,
                  styles[changeStyle],
                  styles.teamColor,
                  getTeamNameClassName(standing.team?.slug)
                )}
              >
                {standing.position}
              </span>
              <div className={styles.details}>
                <div
                  className={styles.driverPhoto}
                  style={{ backgroundImage: `url(/images/drivers/2020/${standing.entrant.slug}.png)` }}
                />
                <span className={styles.name}>{standing.entrant.name}</span>
                {standing.team && standing.entrant.name !== standing.team.name && (
                  <span className={styles.team}>{standing.team.name}</span>
                )}
                <span className={styles.points}>
                  {standing.points}{' '}
                  <span className={styles.pointsLabel}>pts</span>
                </span>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default LeaderBoard;
