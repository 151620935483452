import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './BaseScoreCard.module.scss';

const BaseScoreCard = ({ scores, wrap }) => {
  return (
    <ul className={classNames(styles.scoreCard, wrap ? styles.wrap : null)}>
      {scores.map((score) => (
        <li className={styles.score} key={score.key}>
          <Link
            className={classNames(styles.winner, score.className)}
            to={score.url}
          >
            <span className={styles.key}>{score.key}</span>
            {score.value}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default BaseScoreCard;
